$menu_height: 500px;

.catalog_btn{
	padding: 0;
	> a{
		display: block;
		width: 100%;
		padding: 23px 30px;
    	background-color: $white;
    	color: $dark_blue;
    	font-weight: 900;
    	font-size: 1em;
		font-family: $second-font;
	}
	.menu{
		display: none;
		position: absolute;
		max-height: $menu_height;
	    overflow-y: auto;
	    overflow-x: hidden;

		&::-webkit-scrollbar{
		    width: 0px;
		}
	}
	&:hover{
		.menu{
			display: block;
		}
	}
}

.hamburger{
	position: relative;
	display: inline-block;
	top: -3px;
	width: 32px;
	height: 4px;
	background: $dark_blue;
	border-radius: 2px !important;	
	margin: auto;
	margin-right: 15px;

	&:before,
	&:after{
		content: '';
		position: absolute;
		left: 0;
		width: 32px;
		height: 4px;
		border-radius: 2px !important;
		background: $dark_blue;
	}
	&:before{
		top: -8px;
	}
	&:after{
		top: 8px;
	}
}

.top_menu{
	padding: 0;
}

.menu{
	padding: 0;
	position: relative;
	z-index: 1;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);

	::-webkit-scrollbar{
	    width: 10px;
	}
	::-webkit-scrollbar-thumb{
	    border-width: 1px 1px 1px 2px;
	    border-color: $gray;
	    background-color: $gray;
	}
	::-webkit-scrollbar-thumb:hover{
	    border-width: 1px 1px 1px 2px;
	    border-color: $black;
	    background-color: $orange;
	}
	::-webkit-scrollbar-track{
	    border-width:0;
	}
	::-webkit-scrollbar-track:hover{
	    border-left: solid 1px #aaa;
	    background-color: #eee;
	}

	ul{
		border-top: 2px solid $dark_blue;
	}

	ul, li{
		list-style-type: none;
		padding: 0;
		margin: 0;
	}

	a{
		transition: all 0.25s ease-in-out;		
	}

	li:hover{
		> a{
			&{
				text-decoration: none;
				color: $cyan;
			}
		}
	}

	.fixed_bar{
		display: block;
		position: absolute;
		width: 0;
		overflow: hidden;
		left: 100%;
		top: 0;
		padding: 0;
		> ul{
			position: fixed;
			height: $menu_height;
			width: 0%;
			background-color: $white;
			overflow-y: auto ;
			overflow-x: hidden;
			padding-bottom: 70px;
			opacity: 0;
			transition: all 0.25s ease-in-out;

			&:before{
				display: block;
				position: absolute;
				right: -1px;
				z-index: 0;
				content: " ";
				border: 1px solid $gray;
				height: 100%;
				width: 0px;
			}
		}

		li a{
			border-bottom: none;
			padding: 7.5px 30px;
		}	

	}

	ul > li:hover{
		> .fixed_bar{
			width: 100%;
			> ul{
				opacity: 1;
				width: 300px;
			}
		}
	}

	> ul{
		display: block;
		float: left;
		width: 100%;
		padding: 0;
		> li {
			display: block;
			float: left;
			width: 100%;
			a{
				display: block;
				float: left;
				font-size: 1rem;
				line-height: 1.67em;
				width: 100%;
				padding: 15px 30px;
				color: $font_black;
				border-bottom: 1px solid $gray;
				border-right: 1px solid $gray;
				background-color: $white;
			}

			&:last-child{
				a{
					border-bottom: none;
				}
			}
			&:hover > a{
				border-right: 1px solid $white !important;
			}
		}

		li.parent{
			> a{
				position: relative;

				&:after,
				&:before{
					right: 10px;
					top: 50%;
					border: solid transparent;
					content: " ";
					height: 0;
					width: 0;
					position: absolute;
					pointer-events: none;
				}
				&:after{
					border-color: rgba(136, 183, 213, 0);
					border-left-color: $white;
					border-width: 6px;
					margin-top: -6px;
				}
				&:before{
					border-color:rgba(194, 225, 245, 0);
					border-left-color: darken($gray, 50%);
					border-width: 5px;
					margin-top: -5px;
				}
				
				&:hover{
					&:before{
						border-left-color: $cyan;
					}
				}
			}
		}

		li li.parent{

			&:hover > a{

				&:after,
				&:before{
					right: -1px;
					top: 50%;
					border: solid transparent;
					content: " ";
					height: 0;
					width: 0;
					position: absolute;
					pointer-events: none;
				}
				&:after{
					border-color: rgba(136, 183, 213, 0);
					border-right-color: $white;
					border-width: 30px;
					margin-top: -30px;
				}
				&:before{
					border-color:rgba(194, 225, 245, 0);
					border-right-color: $gray;
					border-width: 31px;
					margin-top: -31px;
				}
			}

			a{
				position: relative;
			}

		}
	}
}

.darken{
	display: none;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.3);
}