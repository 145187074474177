@import "app/applications/city/frontend/scss/modules/mixins";
@import "app/applications/city/frontend/scss/modules/vars";
@import "app/applications/city/frontend/scss/vendor/libs";

@import "app/applications/city/frontend/scss/modules/greedynav";
@import "app/applications/city/frontend/scss/modules/menu";
@import "app/applications/city/frontend/scss/modules/breadcrumbs";
@import "app/applications/city/frontend/scss/modules/visit_cards";
@import "app/applications/city/frontend/scss/partials/main_catalogue";
@import "app/applications/city/frontend/scss/partials/video";

html{
	height: 100%;
	position: relative;
	min-width: 310px;
	overflow: auto;
}
body{
	min-height: 100%;
	background-color: $white;
	padding-bottom: 64px;
	font-size: 16px;
	min-width: 310px;

	&.main_page{
		background-color: #ededed;

		header,
		.greedy-nav{
			background-color: $dark_blue;
		}

		.visible-links a{
			background-color: $dark_blue;
			color: $white;
		}

		.catalog_btn > a{
			background-color: $dark_blue;
			color: $white;

			span,
			span:after,
			span:before{
				background-color: $white;
			}
		}
	}

	@media (max-width: 992px) {
		font-size: 14px;
	}

	@media (max-width: 768px) {
		font-size: 14px;
	}
}

@media (min-width: 1921px) and (max-width: 2560px) {
	.container {
		width: 1900px;
	}
}

@media  (min-width: 1601px) and (max-width: 1920px) {
	.container {
		width: 1500px;
	}
}

@media (min-width: 1201px) and (max-width: 1600px) {
	.container {
		width: 1140px;
	}
}

header{
	position: relative;
	z-index: 5;
    background-color: $white;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
	border-bottom: 2px solid $dark_blue;
}

main{
	overflow: visible;
	position: relative;

	> section,
	> article{
		display: block;
		float: left;
		position: relative;
		width: 100%;
		clear: both;
		padding: 7.5px 0;
	}
	> article{
		font-family: $second-font;
		font-weight: 700;
		padding-bottom: 15px;
	}
}

h1{
	display: block;
	position: relative;
	margin: 0px;
	clear: both;
	font-size: 2.25em;
	line-height: 1.618;

	&:before{
		position: absolute;
		content: "";
		height: 2px;
		width: 45%;
		background-color: $red;
		bottom: 0;
		left: 0;
		transition: all .3s ease-in-out;
	}

	&:hover{
		&:before{
			width: 67.5%;
		}
	}
}

h2{
	font-size: 1.35em;
	line-height: 1.618;
	margin: 0;
}

footer{
	position: absolute;
	width: 100%;
	bottom: 0;
	z-index: 5;
	box-shadow: 0 -2px 5px 0 rgba(0,0,0,.16), 0 -2px 10px 0 rgba(0,0,0,.12);
    background-color: #f0f0f0;

    .footer-content{
		display: block;
		float: left;
		width: 100%;
		padding: 7.5px 0 15px 0;
		font-size: 1em;
		line-height: 1em;
		color: $black;
    	text-align: center;
    }

	svg{
		fill: $black;
	}
}

::-webkit-scrollbar{
    width: 10px;
}
::-webkit-scrollbar-thumb{
    border-width: 1px 1px 1px 2px;
    border-color: $black;
    background-color: lighten($black, 25%);
}
::-webkit-scrollbar-thumb:hover{
    border-width: 1px 1px 1px 2px;
    border-color: $black;
    background-color: $orange;
}
::-webkit-scrollbar-track{
    border-width:0;
}
::-webkit-scrollbar-track:hover{
    border-left: solid 1px #aaa;
    background-color: #eee;
}