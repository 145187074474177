.visits{
	margin: 0 -7.5px;

	> div{
		display: block;
		position: relative;
		z-index: 0;
		float: left;
		width: 20%;
		padding: 7.5px;
		transition: transform .2s ease,border-color .2s ease,-webkit-transform .2s ease;
		cursor: pointer;

		@media (max-width: 2560px) {
			width: 12.5%;
		}

		@media (max-width: 1920px) {
			width: 16.66%;
		}

		@media (max-width: 1600px) {
			width: 20%;
		}

		@media (max-width: 1200px) {
			width: 25%;
		}

		@media (max-width: 992px) {
			width: 33.33%;
		}

		@media (max-width: 768px) {
			width: 33.33%;
		}

		@media (max-width: 650px) {
			width: 33.33%;
		}

		@media (max-width: 480px) {
			width: 50%;
		}

		@media (max-width: 320px) {
		}

		.inner{
			display: block;
			position: relative;
			z-index: 1;
			float: left;
			width: 100%;
			height: 100%;
			padding: 0;
			background-color: darken($white, 1%);
			transition: all .0s ease-in-out;
			border: 1px solid $gray;

			.name{
				display: table;
				position: relative;
				z-index: 1;
				float: left;
				width: 100%;
				margin: 0 0 7.5px 0;
				padding: 7.5px;
				transition: all .0s ease-in-out;
				background-color: $dark_blue;

				h3{
					display: table-cell;
					vertical-align: middle;
					width: 100%;
					font-size: 1.15em;
					font-family: $default-font;
					font-weight: 300;
					color: $white;
				}

				&:after{
					position: absolute;
					content: "";
					height: 2px;
					width: 33.3%;
					background-color: $blue;
					bottom: -3px;
					left: 0;
					transition: all .3s ease-in-out;
				}
			}

			.contact_info{
				display: block;
				position: relative;
				float: left;
				width: 100%;
				padding: 0 7.5px;

				*{
					display: block;
					float: left;
					width: 100%;
					margin: 0;
					font-weight: 300;
				}

				.phones{
					font-weight: 500;
					a{
						font-weight: 500;
						color: $mega_black;
						font-size: 1em;
						line-height: 1.618em;
					}
				}
			}

			.description_wrapper{
				display: block;
				float: left;
				position: relative;
				width: 100%;
				height: 24px;

				.description{
					display: block;
					position: absolute;
					width: 100%;
					width: calc(100% + 2px);
					left: -1px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					height: 25px;
					padding: 0 7.5px;
					font-weight: 300;
					transition: all .1s ease-in-out;
					border-color: $gray;
					border: 1px solid $gray;
					border-top: none;
				}
			}
		}

		&:hover{
			z-index: 1;

			.inner{
				box-shadow: none;
				border: 1px solid $dark_blue;
			}

			.name{
				&:after{
					width: 75%;
				}
			}

			.description_wrapper .description{
				overflow: inherit;
				white-space: inherit;
				min-height: 25px;
				height: auto;
				background-color: darken($white, 1%);
				border-color: $dark_blue;
			}
		}
	}
}