.categories,
.vitits{
	margin-left: -7.5px;
	margin-right: -7.5px;

	> div{
		display: block;
		float: left;
		position: relative;
		background: none;
		width: 16.66%;
		height: 222px;
		padding: 7.5px;

		@media (max-width: 2560px) {
			width: 10%;
		}

		@media (max-width: 1920px) {
			width: 12.5%;
		}

		@media (max-width: 1600px) {
			width: 16.66%;
		}

		@media (max-width: 1200px) {
			width: 20%;
		}

		@media (max-width: 992px) {
			width: 25%;
			height: 175px;
		}

		@media (max-width: 768px) {
		}

		@media (max-width: 650px) {
			width: 33.33%;
		}

		@media (max-width: 480px) {
			width: 50%;
		}

		@media (max-width: 320px) {
		}
	}
	a{
		display: block;
		float: left;
		overflow: hidden;
		position: relative;
		width: 100%;
		height:100%;
		background-color: darken($white, 1%);
		box-shadow: 0 1px 2px 0 rgba(0,0,0,.2);
		transition: all .3s ease-in-out;

		.logotype{
			display: block;
			position: relative;
			overflow: hidden;
			text-align: center;
			z-index: 0;
			top: 0;
			left: 0;
			width: 100%;
			height: 120px;
			transition: all .3s ease-in-out;
			background: rgba(255, 255, 255, 0.5);

			&:after{
				display: inline-block;
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				content: "";
				z-index: 1
			}

			img{
				display: block;
				height: 100%;
				max-width: inherit;
				min-width: 100%;
				margin: 0;
				padding: 0;
				transform: scale(1);
				transition: all .3s ease-in-out;
			}

			@media (max-width: 992px) {
				height: 100px;
			}
		}

		.city{
			position: relative;
			padding: 0px 4px;
			margin: 0 0 0 15px;
			opacity: 0.85;
			color: $white;
			font-size: 0.95em;
			z-index: 1;
			font-family: $second-font;
			font-style: normal;
			font-weight: 900;
			border-radius: 2px;
			background-color: $red;
			transition: all .2s ease-in-out;
		}

		.name{
			display: block;
			float: left;
			width:100%;
			line-height: 1.63em;
			padding: 17px 10px 0px;
			color: $mega_black;

			@media (max-width: 992px) {
				padding: 10px 5px 0px;
			}
		}

		&:hover{
			text-decoration: none;

			.logotype{
				&:after{
					background: rgba(255, 255, 255, 0.15);
				}

				img{
					transform: scale(1.05) rotate(2deg);
				}
			}

			.city{
				background-color: darken($red, 10%);
				opacity: 1;
			}
		}
	}
}