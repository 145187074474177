$default-font: Oswald, Helvetica, Arial, sans-serif;
$second-font:  Helvetica, Arial, sans-serif;

$accent: #F93F3F;
$gray: #e9e9e9;
// $gray: #000000;
$black: #262626;
$mega_black: #000000;
$font_black: #333333;
$green: #4CB227;
$orange: #ff9800;
$blue: #395eaa;
$dark_blue: #2e4b88;
//$blue: #2e4b88;
$cyan: #5cb5e5;
$white: #FFFFFF;
$red: #d30000;