.breadcrumbs{

	ol{
		display: block;
		float: left;
		width: 100%;
		padding: 15px 0px;
		margin: 0;
	}

	li{
		display: block;
		position: relative;
		float: left;
		font-size: 1rem;
		line-height: 1rem;

	}

	a{
		color: $mega_black;
		padding-right: 15px;

		&:after,
		&:before{
			right: 0;
			top: 50%;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
		}
		&:after{
			border-color: rgba(136, 183, 213, 0);
			border-left-color: $gray;
			border-width: 5px;
			margin-top: -5px;
		}
		&:before{
			border-color:rgba(194, 225, 245, 0);
			border-left-color: darken($gray, 50%);
			border-width: 4px;
			margin-top: -4px;
		}

		&:hover{
			color: $cyan;
			text-decoration: none;
			&:before{
				border-left-color: $cyan;
			}
		}
	}
}