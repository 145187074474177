.greedy-nav {
  position: relative;
  min-width: 250px;
  background: $white;

  a {
    display: block;
    padding: 25px 30px;
    font-size: 1rem;
    text-decoration: none;
    transition: .1s ease-in-out;
    background-color: $white;
    color: $mega_black;
    font-family: $second-font;
    font-weight: 900;

    &:hover {
    }
  }
  
  button {
    position: absolute;
    height: 100%;
    right: 0px;
    padding: 0 15px;
    border: 0;
    outline: none;
    background-color: $dark_blue;
    color: #fff;
    cursor: pointer;
    
    &:hover {
    }
    
    &::after {
      content: attr(count);
      position: absolute;
      width: 30px;
      height: 30px;
      left: -16px;
      top: 12px;
      text-align: center;
      background-color: $blue;
      color: #fff;
      line-height: 28px;
      border-radius: 50%;
      border: 3px solid #fff;
      font-weight: bold;
    }
    
    &:hover::after {
      transform: scale(1.075);
    }
  }
  
  .hamburger {
    position: relative;
    width: 32px;
    height: 4px;
    background: #fff;
    margin: auto;
    
    &:before, 
    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 32px;
      height: 4px;
      background: #fff;
    }
    
    &:before {
      top: -8px;
    }
    
    &:after {
      bottom: -8px;
    }
  }
  
  .visible-links {
    display: inline-table;
    margin: 0;
    padding: 0;

    
    li {
      display: table-cell;
      border-left: 1px solid $white;

      &:first-child{
        border-left: none;
      }
    }
  }
  
  .hidden-links {
    position: absolute;
    right: 0px;
    margin: 0;
    padding: 0;
    top: 100%;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    
    li {
      display: block;
      border-top: 1px solid $gray;
    }
  }
  
  .visible-links li:first-child {
  }
  
  .hidden {
    visibility: hidden;
  }
}